import { Block, Layout, Seo } from "@components"

import React from "react"
import { graphql } from "gatsby"

const Page = ({ data: { page } }) => {
  return (
    <Layout
      is404={false}
      hasHero={
        page?.pageBlocks?.pageBlocks !== null
          ? page?.pageBlocks?.pageBlocks[0]?.fieldGroupName ===
            "Page_Pageblocks_PageBlocks_Hero"
            ? true
            : false
          : false
      }
      pageSlug={page?.slug}
    >
      <Seo
        title={page?.seo?.title !== null ? page?.seo?.title : page?.title}
        description={page?.seo?.metaDesc}
      />

      {page?.pageBlocks?.pageBlocks !== null && (
        <>
          {page?.pageBlocks?.pageBlocks?.map((block, index) => {
            return (
              <Block pageTitle={page?.title} key={index} blockData={block} />
            )
          })}
        </>
      )}
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
      slug

      seo {
        metaDesc
        title
      }

      pageBlocks {
        pageBlocks {
          ... on WpPage_Pageblocks_PageBlocks_Hero {
            fieldGroupName
            blockId
            heroSlides {
              heroCaption
              heroLink {
                target
                title
                url
              }
              heroImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 2400
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }

          ... on WpPage_Pageblocks_PageBlocks_EmployeesGrid {
            fieldGroupName
            blockId
            paddingBottom
            paddingTop
            backgroundColour
          }

          ... on WpPage_Pageblocks_PageBlocks_EmployeesCarousel {
            blockIntro
            fieldGroupName
            blockId
            paddingBottom
            paddingTop
            backgroundColour
            employees {
              ... on WpEmployee {
                slug
              }
            }
            employeesSelect
          }

          ... on WpPage_Pageblocks_PageBlocks_ServicesBlock {
            fieldGroupName
            blockId
            servicesBackgroundColour
            paddingBottom
            paddingTop
            backgroundColour
          }

          ... on WpPage_Pageblocks_PageBlocks_Form {
            fieldGroupName
            blockId
            formIntro
            formSelection
            formTitle
            formAccentColour
            formSuccess
            paddingBottom
            paddingTop
            backgroundColour
          }

          ... on WpPage_Pageblocks_PageBlocks_CallToAction {
            ctaColumnOne
            ctaColumnTwo
            ctaLinkColour
            ctaMessage
            ctaTitle
            dropdownContent
            linkType
            fieldGroupName
            blockId
            ctaLink {
              target
              title
              url
            }
            paddingBottom
            paddingTop
            backgroundColour
          }

          ... on WpPage_Pageblocks_PageBlocks_Offices {
            backgroundColour
            fieldGroupName
            paddingBottom
            paddingTop
          }

          ... on WpPage_Pageblocks_PageBlocks_Image {
            backgroundColour
            fieldGroupName
            blockId
            paddingBottom
            paddingTop
            images {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 2400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }

          ... on WpPage_Pageblocks_PageBlocks_TextBlock {
            backgroundColour
            textContent: content
            intro
            paddingBottom
            paddingTop
            fieldGroupName
            blockId
          }
        }
      }
    }
  }
`
